import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { notification, InputNumber } from "antd";

import {
  getStatusText,
  STATUS_PROCESS_REQUEST,
  STATUS_REQUEST,
} from "../../../../constants/enum";
import ButtonCustomize from "../../../../components/button";
import { BtnTypeEnum } from "../../../../components/button/const";
import TableComponent from "../../components/table";
import Modal from "../../../../components/modal/modal-card";
import IconSuccess from "../../../../components/svg/icon-success";
import IconWarning from "../../../../components/svg/icon-warning";
import { useRequest } from "../../useRequest";
import {
  AlertType,
  openNotificationWithIcon,
} from "../../../../utils/AlertMessage";
import { formatInputMoney, formatterNumber, parserNumber } from "../../../../utils/helper";
import { DEFAULT_PAGE, MAX_RESULTS } from "../../../../constants/apiConstants";
import { DATE_FORMAT_CREATE } from "../../../../constants/apiConstants";
import { ProfileInfo } from '../../../client/profile';

const RegRequest = forwardRef(({ regRequestRejectRef, listAllUserRef }, ref) => {
  const { getAllRequestBuyMore, changeRequestBuyMore, getRequestUserInfoAccount } = useRequest();

  const [dataSource, setDataSource] = useState([]);
 
  const [rejectReasonVal, setRejectReason] = useState("");
  const [selected, setSelected] = useState(null);
  const [customerSelected, setCustomerSelected] = useState(null);
  const [priceInput, setPriceInput] = useState(0);
  const [size, setSize] = useState(MAX_RESULTS);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState({});
  const [modalForm, setModalForm] = useState({
    isOpen: false,
    isClose: true,
    title: "",
    type: 0,
  });

  useImperativeHandle(ref, () => ({
    reloadList() {
      onLoadData();
    },
  }));

  useEffect(() => {
    onLoadData();
  }, [page, size]);

  const onLoadData = () => {
    getAllRequestBuyMore({
      status: STATUS_REQUEST.WAIT_REGIS_NEW,
      statusProcess: STATUS_PROCESS_REQUEST.NO_PROCESS,
      pageNumber: page,
      pageSize: size,
    }).then((result) => {
      let newDataRes = [];
      let pageSize = size;
      result?.data?.forEach((element, index) => {
        newDataRes.push({ ...element, key: (page - 1) * pageSize + index + 1 });
      });
      setDataSource(newDataRes);
      setTotal(result.total);
    });
  };

  const onShowModal = (record) => () => {
    if (!record) return;
    setPriceInput(record?.amount || 0);
    const createdAtFormat = record.createdAt
      ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
      : "";
    setSelected({ ...record, createdAtFormat });
    setModalForm({
      isOpen: true,
      title: "Yêu cầu đăng ký dịch vụ",
      type: 1,
    });
  };

  const onGoRejectForm = () => {
    setModalForm({
      isOpen: true,
      title: "Thông báo",
      type: 3,
    });
  };

  const onReject = (onDoneCb) => {
    onCompleted(onDoneCb, true);
  };
  const onApprove = (onDoneCb) => {
    onCompleted(onDoneCb, false);
  };

  const onCompleted = (onDoneCb, isReject) => {
    const payload = {
      requestId: selected.requestId,
      amount:priceInput || selected?.amoun || 0,
      status: isReject
        ? STATUS_PROCESS_REQUEST.REJECT
        : STATUS_PROCESS_REQUEST.DONE,
    };
    if (isReject) {
      if (!rejectReasonVal || rejectReasonVal.trim().length === 0) {
        onDoneCb && onDoneCb();
        openNotificationWithIcon(
          notification,
          AlertType.error,
          "Vui lòng nhập lý do từ chối"
        );
        return;
      }
      payload.reason = rejectReasonVal;
    }

    changeRequestBuyMore(payload).then((result) => {
      onDoneCb && onDoneCb();
      if (result.isSuccess) {
        if (isReject) {
          regRequestRejectRef.current?.reloadList();
          listAllUserRef.current?.reloadList();
        }
        onLoadData();
        handleCancel();
      }
    });
  };

  const handleCancel = () => {
    setModalForm({
      isClose: false,
    });
    setSelected(null);
    setRejectReason("");
  };

  const renderFooter = () => {
    if (modalForm.type === 1) {
      return (
        <>
          <ButtonCustomize
            title={"Thoát"}
            btnType={BtnTypeEnum.OUTLINE}
            btnSize={44}
            onPressHandle={handleCancel}
          />
          <ButtonCustomize
            title={"Từ chối"}
            btnType={BtnTypeEnum.GREY}
            btnSize={44}
            onPressHandle={onGoRejectForm}
          />
          <ButtonCustomize
            title={"Kích hoạt dịch vụ"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            showLoading={true}
            onPressHandle={onApprove}
          />
        </>
      );
    }

    if (modalForm.type === 2) {
      return (
        <>
          <ButtonCustomize
            title={"Hoàn thành"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            onPressHandle={handleCancel}
          />
        </>
      );
    }

    if (modalForm.type === 3) {
      return (
        <>
          <ButtonCustomize
            title={"Hủy bỏ"}
            btnType={BtnTypeEnum.GREY}
            btnSize={44}
            onPressHandle={handleCancel}
          />
          <ButtonCustomize
            title={"Xác nhận từ chối"}
            btnType={BtnTypeEnum.BLACK}
            btnSize={44}
            showLoading={true}
            onPressHandle={onReject}
          />
        </>
      );
    }
  };
  const showInfoUser = (record) => {
    getRequestUserInfoAccount({
      userId: record.userId,
    }).then((res) => {
      if (!res?.isSuccess) {
        setCustomerSelected(res);
        setModalForm({
          isOpen: true,
          title: "Thông tin tài khoản",
          type: 4,
        });
      }

    });
  };
  return (
    <>
      <div className="layout-package">
        {/*<p1>Đã chọn</p1>*/}
        {/*<p2>02</p2>*/}
        {/*<button>Từ chối đăng ký gói</button>*/}
        {/*<button>Kích hoạt gói</button>*/}
      </div>
      <TableComponent
        scroll={{ x: 2500, y: "calc(100vh - 220px)" }}

        dataSource={dataSource}
        bordered={true}
        // rowSelection={true}
        totalCount={total}
        page={page}
        setPage={setPage}
        pageSize={size}
        setSize={setSize}
        columns={[
          {
            title: "STT",
            dataIndex: "key",
            width: 25,
          },
          {
            title: "Khách hàng",
            width: 125,
            dataIndex: "fullName",
            key: "fullName",
            render: (value, record) => {
              return (
                <div
                  className="table-user-column"
                  onClick={() => showInfoUser(record)}
                >
                  <span style={{ color: "#5464F4" }}>{value}</span>
                  <div className="table-box">
                    <span>{record.phoneNumber}</span>
                    <span>-</span>
                    <span>{record.email}</span>
                  </div>
                </div>

              );
            },
          },
          {
            title: "Trạng thái dịch vụ",
            width: 60,
            dataIndex: "statusProcess",
            key: "statusProcess",
            render: () => {
              return <span className="sub-package">Chờ kích hoạt</span>;
            },
          },
          {
            title: "Tên miền",
            dataIndex: "tenantId",
            key: Math.random(),
            width: 100,
            ellipsis: true,
            render: (_, record) => {
              if (record.e) {
                record.e.stopPropagation();
              }
              return (
                <div className="table-user-column">
                  <a
                    target="_blank"
                    href={`https://${record.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
                    rel="noopener noreferrer"
                  >
                    {`https://${record.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
                  </a>
                  <div className="table-box">
                    {record.isActiveDomain === 0 ? (
                      <span className="user-status-locked">Chờ khởi tạo</span>
                    ) : (
                      <>
                        <span className="user-status-active">
                          Đang hoạt động
                        </span>
                      </>
                    )}
                  </div>
                </div>
              );
            },
          },
          {
            title: "Ngày đăng ký dịch vụ",
            width: 80,
            dataIndex: "createdAt",
            key: "createdAt",
            render: (val, record) => {
              const createdAtFormat = record.createdAt
                ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
                : "";
              return <span>{createdAtFormat}</span>;
            },
          },
          {
            title: "Trạng thái TK",
            className: "account-status",
            width: 60,
            dataIndex: "userStatus",
            key: "userStatus",
            render: (val) => {
              return <span>{getStatusText(val)}</span>;
            },
          },
          {
            title: "Gói đang sử dụng",
            width: 60,
            dataIndex: "packageName",
            key: "packageName",
          },
          {
            title: "Dịch vụ mua thêm",
            width: 140,
            dataIndex: "serviceName",
            key: "serviceName",
          },
          {
            title: "Giá dịch vụ",
            width: 80,
            dataIndex: "amount",
            key: "amount",
            render: (val, record) => {
              const amount = record.valueOther > 0 ? record.amount * record.valueOther : record.amount;
              return <div> {formatInputMoney(amount)} đ</div>;
            },
          },

          {
            title: "Hành động",
            width: 51,
            dataIndex: "fullName",
            key: "fullName",
            fixed: "right",
            render: (val, record) => {
              return (
                <ButtonCustomize
                  title={"Kiểm duyệt"}
                  btnType={BtnTypeEnum.GREEN}
                  btnSize={32}
                  onPressHandle={onShowModal(record)}
                />
              );
            },
          },
        ]}
      />
      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onCancel={handleCancel}
        onClose={handleCancel}
        width={"50%"}
        footerNode={renderFooter}
      >
        {/*Yêu cầu đăng ký gói*/}
        {modalForm.type === 1 && (
          <>
            <div className="item-modal">
              <ul>
                <li>Trạng thái</li>
                <li>
                  <span>Chờ kích hoạt</span>
                </li>
              </ul>
              <ul>
                <li>Dịch vụ đăng ký</li>
                <li>{selected?.serviceName}</li>
              </ul>
              <ul>
                <li>Thời gian dịch vụ đăng ký</li>
                <li>{selected?.duration} ngày</li>
              </ul>
              <ul>
                <li>Ngày yêu cầu đăng ký</li>
                <li>{selected?.createdAtFormat}</li>
              </ul>
              <ul>
                <li>Giá dịch vụ</li>
                <li>{formatInputMoney(selected?.amount)} đ</li>
              </ul>
              <ul>
                <li>Số tiền thực tế</li>
                <li>
                  <InputNumber placeholder="Nhập vào"
                    value={priceInput}
                    min={0}
                    formatter={formatterNumber}
                    parser={parserNumber}
                    style={{ minWidth: 210 }}
                    onChange={e => {
                      setPriceInput(e)
                    }}
                  />
                </li>
              </ul>
            </div>
          </>
        )}

        {/*Kích hoạt thành công*/}
        {modalForm.type === 2 && (
          <>
            <div className="modal-success">
              <IconSuccess />
              <span>Kích hoạt gói thành công</span>
            </div>
          </>
        )}

        {/*Từ chối đăng ký gói*/}
        {modalForm.type === 3 && (
          <>
            <div className="modal-refuse">
              <ul>
                <IconWarning />
                <li>Từ chối đăng ký dịch vụ</li>
              </ul>
              <ul>
                <li>Nhập nội dung lý do từ chối</li>
                <TextArea
                  value={rejectReasonVal}
                  rows={4}
                  placeholder="Nhập vào"
                  onChange={(e) => setRejectReason(e.target.value)}
                />
              </ul>
            </div>
          </>
        )}
        {modalForm.type === 4 && (
          <>
            <ProfileInfo
              avatarSize={150}
              user={customerSelected}
              userId={customerSelected.userId}
              reloadData={() => { }}
            />
          </>
        )}
      </Modal>
    </>
  );
});

export default RegRequest;
