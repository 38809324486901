import {API_BASE_URL} from "../../../constants/apiConstants";

export const API_REQUEST_PACKAGE = `${API_BASE_URL}user/change-request-status`;
export const API_LIST_REQUEST = `${API_BASE_URL}user/list-request`;
export const API_EXTEND_PACKAGE= `${API_BASE_URL}user/extend-package`;
export const API_CHANGE_PACKAGE= `${API_BASE_URL}user/change-package`;
export const API_USER_CREATE = `${API_BASE_URL}user/create`;
export const API_USER_LOCK = `${API_BASE_URL}user/lock`;
export const API_USER_LIST_SERVICE = `${API_BASE_URL}user/list-service`;
export const API_POST_USER_INFO= `${API_BASE_URL}user/info`
export const API_DELETE_USER= `${API_BASE_URL}user`
export const API_CHECK_EXISTED_DOMAIN = `${API_BASE_URL}user/check-existed-domain`;
export const API_USER_TRANSACTION_LIST = `${API_BASE_URL}user-transaction/list`;
export const API_UPDATE_INFO_COMPANY = `${API_BASE_URL}user/update-company`
export const API_UPLOAD_FILE = `${API_BASE_URL}upload-file`
export const API_UPDATE_INFO_USER = `${API_BASE_URL}user/update-user`
export const API_GET_INDICATORS = `${API_BASE_URL}user/indicators`
export const API_CREATE_TENANT = `${API_BASE_URL}tenant`;
export const API_INIT_DATA_TENANT = `${API_BASE_URL}initData/tenant`;
export const API_GET_REVENUE = `${API_BASE_URL}revenue-ratio`;
export const API_CREATE_PACKAGE = `${API_BASE_URL}service/create`;
export const API_UPDATE_PACKAGE = `${API_BASE_URL}service/update`;
export const API_DELETE_PACKAGE = `${API_BASE_URL}service/delete`;
export const API_LIST_PACKAGE = `${API_BASE_URL}service/list`;
export const API_EXPIRED_SERVICE = `${API_BASE_URL}expired-service`;
export const API_TOTAL_REGISTER_PACKAGE = `${API_BASE_URL}total-register-package`
export const API_DETAIL_EXPIRED_SERVICE = `${API_BASE_URL}detail-expired-service`;
export const API_TOTAL_EXTEND_PACKAGE = `${API_BASE_URL}total-extend-package`
export const API_TOTAL_CHANGE_PACKAGE = `${API_BASE_URL}total-change-package`
export const API_TOTAL_BUY_MORE_SERVICE = `${API_BASE_URL}total-buy-more`

export const API_BUY_MORE = `${API_BASE_URL}buy-more`;
export const API_LIST_BUY_MORE = `${API_BASE_URL}buy-more/list`;
export const API_LIST_REQUEST_BUY_MORE = `${API_BASE_URL}buy-more/list-request`;
export const API_CHANGE_REQUEST_BUY_MORE = `${API_BASE_URL}buy-more/change-request-status`;
export const API_SEND_USER_REQUEST_BUY_MORE = `${API_BASE_URL}buy-more/send-user-request`;
export const API_BUY_MORE_REGISTERED = `${API_BASE_URL}buy-more/user-registered`;
export const API_ALL_BUY_MORE_REGISTERED = `${API_BASE_URL}buy-more/user-registered-all`;

//Group Object
export const API_CREATE_GROUP_OBJECT = `${API_BASE_URL}group-object/create`
export const API_UPDATE_GROUP_OBJECT = `${API_BASE_URL}group-object/update`
export const API_DELETE_GROUP_OBJECT = `${API_BASE_URL}group-object/delete`
export const API_LIST_GROUP_OBJECT = `${API_BASE_URL}group-object/list`
