import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

import ButtonCustomize from "../../../../../components/button";
import { BtnTypeEnum } from "../../../../../components/button/const";
import { useRequest } from "../../../useRequest";
import {
  DATE_FORMAT_CREATE,
  DEFAULT_PAGE,
  MAX_RESULTS,
} from "../../../../../constants/apiConstants";
import "../../../user/user.scss";
import TableComponent from "../../../../admin/components/table";
import { getUserStatusName } from "../../../../../constants/enum";
import Modal from "../../../../../components/modal/modal-card";
import { ProfileInfo } from '../../../../client/profile';
const ExpireSoonPackage = () => {
  const { getListUsersHandle,getRequestUserInfoAccount } = useRequest();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const statusPackage = query.get("statusPackage") || 3;

  const [dataSource, setDataSource] = useState([]);
  const [size, setSize] = useState(MAX_RESULTS);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState({});
  const [modalForm, setModalForm] = useState({
    isOpen: false,
    isClose: true,
    title: "",
    type: 0,
  });
  const [customerSelected, setCustomerSelected] = useState(null);
  useEffect(() => {
    loadData();
  }, [page, size, statusPackage]);

  const loadData = () => {
    getListUsersHandle({
      pageNumber: page,
      pageSize: size,
      statusPackage,
    }).then((res) => {
      setDataSource(res.data);
      setTotal(res.total);
    });
  };
  const showInfoUser = (record) => {

    getRequestUserInfoAccount({
      userId: record.id,
    }).then((res) => {
      if (!res?.isSuccess) {
        setCustomerSelected(res);
        setModalForm({
          isOpen: true,
          title: "Thông tin tài khoản",
          type: 4,
        });
      }

    });
  };
  const handleCancel = () => {
    setModalForm({
      isClose: false,
    });
    setCustomerSelected(null);

  };
  return (
    <div>
      <TableComponent
        dataSource={dataSource}
        scroll={{y: "calc(100vh - 220px)" }}
        bordered={true}
        // // rowSelection={true}
        totalCount={total}
        page={page}
        setPage={setPage}
        pageSize={size}
        setSize={setSize}
        columns={[
          {
            title: "Khách hàng",
            width: 105,
            dataIndex: "fullName",
            key: "fullName",
            render: (value, record) => {
              return (
                <div
                  className="table-user-column"
                  onClick={() => showInfoUser(record)}
                >
                  <span style={{ color: "#5464F4" }}>{value}</span>
                  <div className="table-box">
                    <span>{record.phoneNumber}</span>
                    <span>-</span>
                    <span>{record.email}</span>
                  </div>
                </div>

              );
            },
          },
          {
            title: "Tên miền",
            dataIndex: "tenantId",
            key: Math.random(),
            width: 100,
            ellipsis: true,
            render: (_, record) => {
              if (record.e) {
                record.e.stopPropagation();
              }
              return (
                <div className="table-user-column">
                  <a
                    target="_blank"
                    href={`https://${record.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
                    rel="noopener noreferrer"
                  >
                    {`https://${record.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
                  </a>
                  <div className="table-box">
                    {record.isActiveDomain === 0 ? (
                      <span className="user-status-locked">Chờ khởi tạo</span>
                    ) : (
                      <>
                        <span className="user-status-active">
                          Đang hoạt động
                        </span>
                      </>
                    )}
                  </div>
                </div>
              );
            },
          },
          {
            title: "Trạng thái TK",
            width: 80,
            dataIndex: "status",
            key: "status",
            render: (val, record) => {
              const { statusName, clsName } = getUserStatusName(record.status);
              return (
                <div className="table-user-management">
                  <span className={clsName}>{statusName}</span>
                </div>
              );
            },
          },
          {
            title: "Gói đăng ký",
            width: 80,
            dataIndex: "packageName",
            key: Math.random(),
          },
          {
            title: "Ngày đăng ký",
            width: 80,
            dataIndex: "startAt",
            key: "startAt",
            render: (val, record) => {
              return (
                <span>
                  {record?.startAt
                    ? dayjs(record.startAt).format("YYYY-MM-DD")
                    : ""}
                </span>
              );
            },
          },
          {
            title: "Trạng thái gói",
            width: 80,
            dataIndex: "statusProcess",
            key: "statusProcess",
            render: (val, record) => {
              const expiredPkg =
                record.isExpireSoon === true && record.userPkgStatus === 1;
              if (expiredPkg) {
                return (
                  <div className="table-user-management">
                    <span className="package-status package-status-expire_soon">
                      Sắp hết hạn
                    </span>
                  </div>
                );
              }
            },
          },
          {
            title: "Hành động",
            width: 105,
            dataIndex: "action",
            key: "action",
            render: (val, record) => {
              return (
                <ButtonCustomize
                  title={"Xác nhận, đã liên hệ"}
                  btnType={BtnTypeEnum.OUTLINE}
                  btnSize={32}
                  // onPressHandle={onShowModal(record)}
                />
              );
            },
          },
        ]}
      />
         <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onCancel={handleCancel}
        onClose={handleCancel}
        width={"50%"}
        footerNode={false}
      >
     
      
        {modalForm.type === 4 && (
          <>
            <ProfileInfo
              avatarSize={150}
              user={customerSelected}
              userId={customerSelected.userId}
              reloadData={() => { }}
            />
          </>
        )}
      </Modal>
    </div>
  );
};
export default ExpireSoonPackage;
