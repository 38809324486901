import ElipseLeft from "../../svg/elipseLeft";
import ElipseTop from "../../svg/elipseTop";
import ElipseRight from "../../svg/elipseRight";
import Logo from "../../svg/logo";
import LogoTesop from "../../../assets/images/logo_tesop.png";
import Star from "../../svg/star";
import Line from "../../svg/line";
import LoginForm from "../LoginForm/LoginForm";
import RegistrationForm from "../Registration/RegistrationForm";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./index.scss";

function LoginRegister() {
   return (
    <>
      <div className="header">
        {process.env.REACT_APP_IS_SAAS_PROD === "true" ? (
          <Logo />
        ) : (
          <img src={LogoTesop} alt={""} width={150} />
        )}
        <div className="comment">
          <Star />
          4.9/5
        </div>
      </div>
      <div className={"container-login"}>
        <div className="container-icon">
          <div className={"ellipse-left"}>
            <ElipseLeft />
          </div>
          <div className={"ellipse-top"}>
            <ElipseTop />
          </div>
          <div className={"ellipse-right"}>
            <ElipseRight />
          </div>
        </div>
        <LoginForm iop={1650}/>
       </div>
      <div className="footer">
        <span className="footer-text">© 2024 Crm360</span> <Line />
        <span className="footer-text">Hỗ trợ</span>
      </div>
    </>
  );
}

export default withRouter(LoginRegister);
