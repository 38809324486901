import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { InputNumber } from "antd";

import {
  getStatusText,
  STATUS_PROCESS_REQUEST,
  STATUS_REQUEST,
} from "../../../../constants/enum";
import ButtonCustomize from "../../../../components/button";
import { BtnTypeEnum } from "../../../../components/button/const";
import TableComponent from "../../components/table";
import Modal from "../../../../components/modal/modal-card";
import IconSuccess from "../../../../components/svg/icon-success";
import IconWarning from "../../../../components/svg/icon-warning";
import { useRequest } from "../../useRequest";
import IconCircleDown from "../../../../components/svg/icon-circle-down";
import { useUser } from "../../../client/useUser";
import { formatInputMoney, formatterNumber, parserNumber } from "../../../../utils/helper";
import { DEFAULT_PAGE, MAX_RESULTS } from "../../../../constants/apiConstants";
import { DATE_FORMAT_CREATE } from "../../../../constants/apiConstants";
import { ProfileInfo } from '../../../client/profile';
const ListAllUser = forwardRef(
  ({ regRequestRef, regRequestRejectRef }, ref) => {
    const { getAllUserRequest, changePackage, changeUserRequest, getRequestUserInfoAccount } =
      useRequest();
    const { getUserPackageUsingHandle } = useUser();
    const [dataSource, setDataSource] = useState([]);
    const [priceInput, setPriceInput] = useState(0);

    const [rejectReasonVal, setRejectReason] = useState("");
    const [selected, setSelected] = useState(null);
    const [userPackage, setUserPackage] = useState(null);
    const [customerSelected, setCustomerSelected] = useState(null);

    const [size, setSize] = useState(MAX_RESULTS);
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [total, setTotal] = useState({});
    const [modalForm, setModalForm] = useState({
      isOpen: false,
      isClose: true,
      title: "",
      type: 0,
    });

    useImperativeHandle(ref, () => ({
      reloadList() {
        onLoadData();
      },
    }));

    useEffect(() => {
      onLoadData();
    }, [page, size]);

    const onLoadData = () => {
      getAllUserRequest({
        status: STATUS_REQUEST.WAIT_CHANGE,
        statusProcess: STATUS_PROCESS_REQUEST.ALL,
        pageNumber: page,
        pageSize: size,
      }).then((result) => {
        let newDataRes = [];
        let pageSize = size;
        result?.data?.forEach((element, index) => {
          newDataRes.push({
            ...element,
            key: (page - 1) * pageSize + index + 1,
          });
        });
        setDataSource(newDataRes);
        setTotal(result.total);
      });
    };

    const onShowModal = (record) => () => {
      if (!record) return;
      setPriceInput(record?.amount || selected?.amount || 0)

      getUserPackageUsingHandle({
        userId: record.userId,
        durationExtend: record.duration,
      }).then((result) => {
        const startAtFormat = result.data.startAt
          ? dayjs(result.data.startAt).format(DATE_FORMAT_CREATE)
          : "";
        const expiredAtFormat = result.data.expiredAt
          ? dayjs(result.data.expiredAt).format(DATE_FORMAT_CREATE)
          : "";
        setUserPackage({ ...result.data, startAtFormat, expiredAtFormat });
      });
      const createdAtFormat = record.createdAt
        ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
        : "";
      setSelected({ ...record, createdAtFormat });
      setModalForm({
        isOpen: true,
        title: "Yêu cầu thay đổi gói",
        type: 1,
      });
    };

    const onChangePackage = (onDoneCb) => {
      changePackage({ requestId: selected?.requestId, amount: priceInput || selected?.amount || 0, }).then(function (result) {
        onDoneCb && onDoneCb();
        if (result.isSuccess) {
          onLoadData();
          setModalForm({
            isOpen: true,
            title: "Thông báo",
            type: 2,
          });
        }
      });
    };

    const onGoRejectForm = () => {
      setModalForm({
        isOpen: true,
        title: "Thông báo",
        type: 3,
      });
    };

    const onShowModalReject = (record) => () => {
      if (!record) return;
      setSelected(record);
      setModalForm({
        isOpen: true,
        title: "Lý do từ chối",
        type: 4,
      });
    };

    const onConfirm = () => {
      changeUserRequest({
        requestId: selected.requestId,
        status: STATUS_PROCESS_REQUEST.NO_PROCESS,
      }).then(() => {
        regRequestRef.current?.reloadList();
        regRequestRejectRef.current?.reloadList();
        onLoadData();
        handleCancel();
      });
    };

    const onReject = (onDoneCb) => {
      changeUserRequest({
        requestId: selected.requestId,
        status: STATUS_PROCESS_REQUEST.REJECT,
        reason: rejectReasonVal,
      }).then((result) => {
        onDoneCb && onDoneCb();
        if (result.isSuccess) {
          regRequestRef.current?.reloadList();
          regRequestRejectRef.current?.reloadList();
          onLoadData();
          handleCancel();
        }
      });
    };

    const handleCancel = () => {
      setModalForm({
        isClose: false,
      });
      setSelected(null);
      setRejectReason("");
    };

    const renderFooter = () => {
      if (modalForm.type === 1) {
        return (
          <>
            <ButtonCustomize
              title={"Thoát"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            <ButtonCustomize
              title={"Từ chối"}
              btnType={BtnTypeEnum.GREY}
              btnSize={44}
              onPressHandle={onGoRejectForm}
            />
            <ButtonCustomize
              title={"Xác nhận thay đổi"}
              btnType={BtnTypeEnum.GREEN}
              btnSize={44}
              showLoading={true}
              onPressHandle={onChangePackage}
            />
          </>
        );
      }

      if (modalForm.type === 2) {
        return (
          <>
            <ButtonCustomize
              title={"Hoàn thành"}
              btnType={BtnTypeEnum.GREEN}
              btnSize={44}
              onPressHandle={handleCancel}
            />
          </>
        );
      }

      if (modalForm.type === 3) {
        return (
          <>
            <ButtonCustomize
              title={"Hủy bỏ"}
              btnType={BtnTypeEnum.GREY}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            <ButtonCustomize
              title={"Xác nhận từ chối"}
              btnType={BtnTypeEnum.BLACK}
              btnSize={44}
              showLoading={true}
              onPressHandle={onReject}
            />
          </>
        );
      }

      if (modalForm.type === 4) {
        return (
          <>
            <ButtonCustomize
              title={"Chuyển về trạng thái yêu cầu"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={onConfirm}
            />
            <ButtonCustomize
              title={"Thoát"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
          </>
        );
      }
    };
    const priceFormat = userPackage?.service?.packageList?.price || 0;
    const showInfoUser = (record) => {
      getRequestUserInfoAccount({
        userId: record.userId,
      }).then((res) => {
        if (!res?.isSuccess) {
          setCustomerSelected(res);
          setModalForm({
            isOpen: true,
            title: "Thông tin tài khoản",
            type: 5,
          });
        }

      });
    };
    return (
      <>
        <div className="layout-package">
          {/*<p1>Đã chọn</p1>*/}
          {/*<p2>02</p2>*/}
          {/*<button>Từ chối đăng ký gói</button>*/}
          {/*<button>Kích hoạt gói</button>*/}
        </div>
        <TableComponent
          scroll={{ x: 2500, y: "calc(100vh - 220px)" }}
          dataSource={dataSource}
          bordered={true}
          // rowSelection={true}
          totalCount={total}
          page={page}
          setPage={setPage}
          pageSize={size}
          setSize={setSize}
          columns={[
            {
              title: "STT",
              dataIndex: "key",
              width: 20,
            },
            {
              title: "Khách hàng",
              width: 105,
              dataIndex: "fullName",
              key: "fullName",
              render: (value, record) => {
                return (
                  <div
                    className="table-user-column"
                    onClick={() => showInfoUser(record)}
                  >
                    <span style={{ color: "#5464F4" }}>{value}</span>
                    <div className="table-box">
                      <span>{record.phoneNumber}</span>
                      <span>-</span>
                      <span>{record.email}</span>
                    </div>
                  </div>

                );
              },
            },
            {
              title: "Trạng thái gói",
              width: 45,
              dataIndex: "fullName",
              key: "fullName",
              render: (_, record) => {
                const getStatusText = (val, row) => {
                  if (row.statusProcess === 0) {
                    return <span className="sub-package">Chờ thay đổi</span>;
                  }

                  if (row.statusProcess === 1) {
                    return <span className="sub-status">Đã thay đổi</span>;
                  }

                  if (row.statusProcess === 2) {
                    return <span className="reject-status">Bị từ chối</span>;
                  }
                };
                return (
                  <>
                    <div>{getStatusText(record.statusProcess, record)}</div>
                  </>
                );
              },
            },
            {
              title: "Tên miền",
              dataIndex: "tenantId",
              key: Math.random(),
              width: 100,
              ellipsis: true,
              render: (_, record) => {
                if (record.e) {
                  record.e.stopPropagation();
                }
                return (
                  <div className="table-user-column">
                    <a
                      target="_blank"
                      href={`https://${record.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
                      rel="noopener noreferrer"
                    >
                      {`https://${record.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
                    </a>
                    <div className="table-box">
                      {record.isActiveDomain === 0 ? (
                        <span className="user-status-locked">Chờ khởi tạo</span>
                      ) : (
                        <>
                          <span className="user-status-active">
                            Đang hoạt động
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                );
              },
            },
            {
              title: "Ngày yêu cầu thay đổi",
              width: 65,
              dataIndex: "createdAt",
              key: "createdAt",
              render: (val, record) => {
                const createdAtFormat = record.createdAt
                  ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
                  : "";
                return <span>{createdAtFormat}</span>;
              },
            },
            {
              title: "Trạng thái TK",
              className: "account-status",
              width: 45,
              dataIndex: "userStatus",
              key: "userStatus",
              render: (val) => {
                return <span>{getStatusText(val)}</span>;
              },
            },
            {
              title: "Gói đang sử dụng",
              width: 56,
              dataIndex: "usingPkgName",
              key: "usingPkgName",
            },
            {
              title: "Gói thay đổi",
              width: 45,
              dataIndex: "serviceName",
              key: "serviceName",
            },
            {
              title: "Giá gói ",
              width: 60,
              dataIndex: "amount",
              key: "amount",
              render: (_, record) => {
                return <span>{formatInputMoney(record.amount)} đ</span>;
              },
            },

            {
              title: "Hành động",
              width: 45,
              dataIndex: "fullName",
              key: "fullName",
              fixed: "right",
              render: (val, record) => {
                const buttonStatus = (val, row) => {
                  if (row.statusProcess === 0) {
                    return (
                      <ButtonCustomize
                        title={"Kiểm duyệt"}
                        btnType={BtnTypeEnum.GREEN}
                        btnSize={32}
                        onPressHandle={onShowModal(row)}
                      />
                    );
                  }

                  if (row.statusProcess === 1) {
                    return null;
                  }

                  if (row.statusProcess === 2) {
                    return (
                      <ButtonCustomize
                        title={"Xem lý do"}
                        btnType={BtnTypeEnum.OUTLINE}
                        btnSize={32}
                        onPressHandle={onShowModalReject(row)}
                      />
                    );
                  }
                };
                return <>{buttonStatus(record.action, record)}</>;
              },
            },
          ]}
        />
        <Modal
          showModal={modalForm.isOpen}
          item={modalForm.title}
          onCancel={handleCancel}
          onClose={handleCancel}
          footerNode={renderFooter}
          width={"50%"}
        >
          {/*Yêu cầu đăng ký gói*/}
          {modalForm.type === 1 && (
            <>
              <div className="box-modal">
                <ol>
                  <li>Trạng thái</li>
                  <li>Chờ thay đổi</li>
                </ol>
                <div className="content-modal">
                  <span>Gói đang dùng</span>
                  <ul>
                    <li>Tên gói</li>
                    <li>{userPackage?.service?.name}</li>
                  </ul>
                  <ul>
                    <li>Thời gian còn lại</li>
                    <li>{userPackage?.remainDay} Ngày</li>
                  </ul>
                  <ul>
                    <li>Ngày đăng ký</li>
                    <li>{userPackage?.startAtFormat}</li>
                  </ul>
                  <ul>
                    <li>Ngày hết hạn</li>
                    <li>{userPackage?.expiredAtFormat}</li>
                  </ul>
                  <ul>
                    <li>Giá gói</li>
                    <li>{formatInputMoney(priceFormat)} đ</li>
                  </ul>
                </div>
                <p>
                  <IconCircleDown />
                </p>
                <div className="content-modal">
                  <span>Gói thay đổi</span>
                  <ul>
                    <li>Tên gói</li>
                    <li>{selected?.serviceName}</li>
                  </ul>
                  <ul>
                    <li>Ngày yêu cầu thay đổi</li>
                    <li>{selected?.createdAtFormat}</li>
                  </ul>
                  <ul>
                    <li>Giá gói</li>
                    <li>{formatInputMoney(selected?.amount)} đ</li>
                  </ul>
                  <ul>
                    <li>Số tiền thực tế</li>
                    <li>
                      <InputNumber placeholder="Nhập vào"
                        value={priceInput}
                        min={0}
                        formatter={formatterNumber}
                        parser={parserNumber}
                        style={{ minWidth: 210 }}
                        onChange={e => {
                          setPriceInput(e)
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}

          {/* Kích hoạt thành công */}
          {modalForm.type === 2 && (
            <>
              <div className="modal-success">
                <IconSuccess />
                <span>Thay đổi gói thành công</span>
              </div>
            </>
          )}

          {/* Từ chối đăng ký gói */}
          {modalForm.type === 3 && (
            <>
              <div className="modal-refuse">
                <p1>
                  <IconWarning />
                  <span>Từ chối thay đổi gói</span>
                </p1>
                <p2>
                  <span>Nhập nội dung lý do từ chối</span>
                  <TextArea
                    placeholder="Nhập vào"
                    autoSize={{ minRows: "100px", maxRows: 5 }}
                    onChange={(e) => setRejectReason(e.target.value)}
                  />
                </p2>
              </div>
            </>
          )}

          {/*Lý do từ chối*/}
          {modalForm.type === 4 && (
            <div className="reason-refuse">
              <p>{selected?.rejectReason}</p>
            </div>
          )}
          {modalForm.type === 5 && (
            <>
              <ProfileInfo
                avatarSize={150}
                user={customerSelected}
                userId={customerSelected.userId}
                reloadData={() => { }}
              />
            </>
          )}
        </Modal>
      </>
    );
  }
);

export default ListAllUser;
