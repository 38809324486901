export const mapToForm = (onValuesArray) => {
    let objForm = {};
    for (let item of onValuesArray) {
        let itemForm = {[item.name]: item.value};
        objForm = {...itemForm, ...objForm};
    }
    return objForm;
};

export const checkIsPhoneNumber = (phone) => {
    const regex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return regex.test(phone);
}

export const changePhoneNumberPrefix = (phoneNumber) => {
    if (!phoneNumber) {
        return phoneNumber;
    }

    if (phoneNumber.startsWith("84")) {
        phoneNumber = "0" + phoneNumber.slice(2);
    } else if (phoneNumber.startsWith("+84")) {
        phoneNumber = "0" + phoneNumber.slice(3);
    } else if (!phoneNumber.startsWith("0") && !phoneNumber.startsWith("84") && !phoneNumber.startsWith("+84")) {
        phoneNumber = "0" + phoneNumber;
    }

    return phoneNumber;
}
export const changePhoneNumberPrefix84 = (phoneNumber) => {
    if (!phoneNumber) {
        return phoneNumber;
    }

    if (phoneNumber.startsWith("84")) {
        phoneNumber = "+84" + phoneNumber.slice(2);
    } else if (phoneNumber.startsWith("+84")) {
        phoneNumber = "+84" + phoneNumber.slice(3);
    } else if (!phoneNumber.startsWith("0") && !phoneNumber.startsWith("84") && !phoneNumber.startsWith("+84")) {
        phoneNumber = "+84" + phoneNumber;
    }else if (phoneNumber.startsWith("0")) {
        phoneNumber = "+84" + phoneNumber.slice(1);
    }

    return phoneNumber;
}

export const formatInputMoney = (n) => {
    if (n <= 0) return 0;
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
export const formatNumber = (num: any, unit = "đ",replaceValue = ".") => {
    return num ? num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, replaceValue) + " " + unit : "";
};

export function formatObjectToArrayKeyValue(object) {
    if (!object || object.length === 0) {
        return []
    }
    let arrayKeys = Object.keys(object);
    let Values = Object.values(object);
    let result = [];

    for (let i = 0; i < arrayKeys.length; i++) {
        result = [...result, {name: arrayKeys[i], value: Values[i]}];
    }
    return result;
}
export function chunksArray(inputArray,perChunk) {
    return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, []);
}

// formatter and parser input number
export const formatterNumber = (val) => {
    if (!val) return 0;
    return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.').replace(/\.(?=\d{0,2}$)/g, ',');
};

export const parserNumber = (val) => {
    if (!val) return 0;
    return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2);
};