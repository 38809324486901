import firebaseConfig from "./config/firebaseConfig.json";
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
const firebaseApp = initializeApp(firebaseConfig);
const YOUR_RECAPTCHA_SITE_KEY = "123@123Aa"
const testkey = "0859F398-BD3A-422A-829B-F65FBB7E2BBB"
const auth = getAuth(firebaseApp)
 

if (window.location.hostname === "localhost") {
   auth.settings.appVerificationDisabledForTesting = true
}

 

export {auth}
export default firebaseApp;