import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";

import { KEY_USER_LOGIN } from "../../../constants/apiConstants";
import { UserObserver } from "../../../observer/ueser/UserObserver";
import { businessType } from "../../../constants/businessFieldType";
import ButtonCustomize from "../../../components/button";
import { BtnTypeEnum } from "../../../components/button/const";
import TableComponent from "../../../pages/admin/components/table";
import { PackageUseCase } from "../../../observer/package/ServiceObserver";
import PackageUserCommon from "../components/package";
import { useBuyMore } from "../../admin/buyMore/useBuyMore";
import dayjs from "dayjs";
import { EBuyMoreSystem, STATUS_PACKAGE } from "../../../constants/enum";
import Modal from "../../../components/modal/modal-card";
import RegNewService from "../services/RegNew";
import { packageUsingSelector } from "../../../redux/slice/user/userSelector";
import { useUser } from "../useUser";
import "./profile.scss";
import { formatInputMoney } from "../../../utils/helper";
import { DEFAULT_PAGE, MAX_RESULTS } from "../../../constants/apiConstants";
import { Form, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";

export const ProfileInfo = (props) => {
  const { user, userId, reloadData } = props;
 
  let useCase = PackageUseCase();
  const [totalDays, setTotalDays] = useState(0);
  const pkgUser = useSelector(packageUsingSelector);

  let userAccount = useMemo(() => {
    return localStorage.getItem(KEY_USER_LOGIN)
      ? JSON.parse(localStorage.getItem(KEY_USER_LOGIN))
      : null;
  }, []);
  const isAdmin = userAccount && userAccount.is_admin;

  const [modalForm, setModalForm] = useState({
    isOpen: false,
    title: "",
    type: 0, // 0: ticket , 1: system, 2: success
  });
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [size, setSize] = useState(MAX_RESULTS);
  const [items, setItems] = useState([]);
  const [usePackage, setUsePackage] = useState([]);
  const [data, setData] = useState([]);
  const [listTransaction, setListTransaction] = useState([]);

  const { getRequestBuyMoreRegApi } = useBuyMore();
  const {
    sendRequestBuyMore,
    getListPackage,
    getUserPackageUsingHandle,
    getUserRequestTransactionList,
  } = useUser();

  const formRegRef = useRef(null);

  useEffect(() => {
    getListPackage();
    getUserPackageUsingHandle();
  }, []);

  useEffect(() => {
    if (pkgUser) {
      const days = Math.ceil(
        Math.max(0, dayjs(pkgUser.expiredAt).diff(dayjs(), "month", true))
      );
      setTotalDays(days);
    }
  }, [pkgUser]);

  useEffect(() => {
    useCase
      .listServices({
        pageNumber: page,
        pageSize: size,
      })
      .then((res) => {
        setItems(res?.items || []);
      });
    useCase
      .findUserPackage({
        userId: userId,
      })
      .then((data) => {
        setUsePackage(data || {});
      });

    getRequestBuyMoreRegApi({
      userId: userId,
    }).then((res) => {
      setData(res.data || {});
    });

    getUserRequestTransactionList({
      pageNumber: page,
      pageSize: size,
      userId: userId,
    }).then((res) => {
      setListTransaction(res || {});
    });
  }, [page, size]);

  const onRegNewFinish = async (onDoneCb) => {
    const payload = formRegRef.current?.getDataForm();
    const resData = await sendRequestBuyMore(payload);
    onDoneCb && onDoneCb();
    if (resData.isSuccess) {
      formRegRef.current?.clearForm();
      setModalForm((prev) => ({
        ...prev,
        title: "Thông báo",
        type: 2,
      }));
    }
  };

  const apiPackage = usePackage?.service;

  const renderBodyModal = () => {
    return (
      <RegNewService
        ref={formRegRef}
        titleForm={modalForm.title}
        formType={modalForm.type}
        totalDays={totalDays}
      />
    );
  };
  const renderFooterModal = () => {
    if (modalForm.type === 2)
      return (
        <ButtonCustomize
          title="Hoàn thành"
          btnType={BtnTypeEnum.GREEN}
          btnSize={44}
          onPressHandle={handleCancel}
        />
      );

    return (
      <>
        <ButtonCustomize
          title="Thoát"
          btnType={BtnTypeEnum.OUTLINE}
          btnSize={44}
          onPressHandle={handleCancel}
        />
        <ButtonCustomize
          title="Xác nhận, Đăng ký"
          btnType={BtnTypeEnum.GREEN}
          btnSize={44}
          showLoading={true}
          onPressHandle={onRegNewFinish}
        />
      </>
    );
  };

  const onUserReg = (buyMoreItem) => {
    setModalForm({
      isOpen: true,
      title: "",
      type: 1,
    });

    const params = {
      ...buyMoreItem,
      id: buyMoreItem.serviceId,
      name: buyMoreItem.serviceName,
      typeSystem: buyMoreItem.systemType,
    };
    if (formRegRef.current) {
      formRegRef.current?.setFormSource(params);
    } else {
      setTimeout(() => {
        formRegRef.current?.setFormSource(params);
      }, 200);
    }
  };
  const handleCancel = () => {
    formRegRef.current?.clearForm();
    setModalForm({ isOpen: false });
  };
  return (
    <>
      <div className="container-profile">
        <span>Thông tin tài khoản</span>
        {!isAdmin ? (
          <div className="body-profile">
            <ProfileCard
              isAdmin={isAdmin}
              reloadData={reloadData}
              user={user}
            />
            <BusinessInfoCard
              user={user}
              isAdmin={isAdmin}
              reloadData={reloadData}
            />
          </div>
        ) : (
          <div className="body-card-profile">
            <ProfileCard
              isAdmin={isAdmin}
              reloadData={reloadData}
              user={user}
            ></ProfileCard>
            <BusinessInfoCard
              user={user}
              isAdmin={isAdmin}
              reloadData={reloadData}
            />
          </div>
        )}

        {usePackage?.id && (
          <div className="card-package">
            <span>Các dịch vụ đang sử dụng</span>
            {/*  <div className="box-package">*/}
            {/*    <span>Gói CRM360 {user?.serviceName || ""}</span>*/}
            {/*    <div className="content-use">*/}
            {/*      {subcriptionData.map((item, index) => {*/}
            {/*        return (*/}
            {/*          <ul key={index}>*/}
            {/*            <p>{item.label}</p>*/}
            {/*            <p>{item.value}</p>*/}
            {/*          </ul>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </div>*/}
            {/*    <icon>*/}
            {/*      <Vector />*/}
            {/*    </icon>*/}
            {/*    <div className="container-btn">*/}
            {/*      <ButtonCustomize*/}
            {/*        title={"Gia hạn gói"}*/}
            {/*        btnType={BtnTypeEnum.WHITE}*/}
            {/*        btnSize={32}*/}
            {/*      />*/}
            {/*      <ButtonCustomize*/}
            {/*        title={"Thay đổi gói"}*/}
            {/*        btnType={BtnTypeEnum.WHITE}*/}
            {/*        btnSize={32}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}

            <PackageUserCommon
              packageUser={usePackage}
              apiPackage={apiPackage}
              items={items}
            />

            <PackageList packages={data} onUserReg={onUserReg} />
          </div>
        )}

        <PaymentHistory
          listTotal={listTransaction?.[0]?.totalCount || 0}
          page={page}
          size={size}
          setPage={setPage}
          setSize={setSize}
          listTransaction={listTransaction}
        ></PaymentHistory>
      </div>

      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onClose={handleCancel}
        footerNode={renderFooterModal}
      >
        {renderBodyModal()}
      </Modal>
    </>
  );
};

function ProfileCard(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { updateInfoUser } = useUser();
  const [form] = Form.useForm();
  const [userInfo, setUserInfo] = useState(props.user);

  useEffect(() => {
    setUserInfo(props.user);
  }, [props.user]);

 
  const showModal = () => {
    setIsModalVisible({
      ...isModalVisible,
      title: "Thay đổi thông tin khách hàng",
      isOpen: true,
    });
    form.setFieldsValue({
      fullName: userInfo?.fullName || "",
      username: userInfo?.username || "",
      email: userInfo?.email || "",
      phoneNumber: userInfo?.phoneNumber || "",
    });
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await updateInfoUser({
        id: props.user.userId,
        fullName: values.fullName || "",
        username: values.username || "",
        email: values.email || "",
        phoneNumber: values.phoneNumber || "",
      });
      setUserInfo({
        ...userInfo,
        fullName: values.fullName,
        username: values.username,
        email: values.email,
        phoneNumber: values.phoneNumber,
      });
      setIsModalVisible(false);
      props.reloadData();
    } catch (error) {
      console.error("Failed to update company info:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderFooterInfoCompany = () => {
    return (
      <>
        <ButtonCustomize
          title="Hủy bỏ"
          btnType={BtnTypeEnum.OUTLINE}
          btnSize={44}
          onPressHandle={handleCancel}
        />
        <ButtonCustomize
          title="Lưu"
          btnType={BtnTypeEnum.GREEN}
          btnSize={44}
          onPressHandle={handleOk}
        />
      </>
    );
  };

  return (
    <div className="card-profile">
      <div className="card-body-profile">
        <p>Thông tin cá nhân</p>
        <EditOutlined onClick={showModal} />
      </div>
      <div className="box-profile">
        <p>Họ tên</p>
        <p>{userInfo?.fullName}</p>
        <p>Tài khoản:</p>
        <p>{userInfo?.username}</p>
        <p>Email:</p>
        <p>{userInfo?.email}</p>
        <p>Số điện thoại:</p>
        <p>{userInfo?.phoneNumber}</p>
      </div>
      <Modal
        showModal={props.modalForm?.isOpen}
        item={isModalVisible.title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footerNode={renderFooterInfoCompany}
        onClose={handleCancel}
      >
        <Form className="body-info" form={form} layout="vertical">
          <Form.Item
            name="fullName"
            label={<div className="title-info">Họ và tên</div>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="username"
            label={<div className="title-info">Tài khoản</div>}
            rules={[
              {
                pattern: /^[a-zA-Z0-9]*$/,
                message:
                  "Tài khoản không được chứa ký tự đặc biệt, dấu cách hoặc ký tự có dấu.",
              },
            ]}
          >
            <Input disabled={!props.isAdmin} />
          </Form.Item>
          <Form.Item
            name="email"
            label={<div className="title-info">Email</div>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label={<div className="title-info">Số điện thoại</div>}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

function BusinessInfoCard(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { updateInfoCompany } = useUser();
  const [form] = Form.useForm();
  const [userInfo, setUserInfo] = useState(props.user);

  useEffect(() => {
    setUserInfo(props.user);
  }, [props.user]);

 
  const showModal = () => {
    setIsModalVisible({
      ...isModalVisible,
      title: "Thay đổi thông tin doanh nghiệp",
      isOpen: true,
    });
    form.setFieldsValue({
      companyName: userInfo?.companyName,
      tenantId: userInfo?.tenantId,
      taxCode: userInfo?.taxCode,
      business_type: userInfo?.business_type,
      address: userInfo?.address,
      cardNumber: userInfo?.cardNumber,
    });
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await updateInfoCompany({
        id: props.user.userId,
        tenantId: values.tenantId,
        companyName: values.companyName,
        taxCode: values.taxCode,
        business_type: values.business_type,
        address: values.address,
        cardNumber: values.cardNumber,
      });
      setUserInfo({
        ...userInfo,
        tenantId: values.tenantId,
        companyName: values.companyName,
        taxCode: values.taxCode,
        business_type: values.business_type,
        address: values.address,
        cardNumber: values.cardNumber,
      });
      setIsModalVisible(false);
    } catch (error) {
      console.error("Failed to update company info:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderFooterInfoCompany = () => {
    return (
      <>
        <ButtonCustomize
          title="Hủy bỏ"
          btnType={BtnTypeEnum.OUTLINE}
          btnSize={44}
          onPressHandle={handleCancel}
        />
        <ButtonCustomize
          title="Lưu"
          btnType={BtnTypeEnum.GREEN}
          btnSize={44}
          onPressHandle={handleOk}
        />
      </>
    );
  };

  return (
    <>
      {userInfo?.tenantId !== null && (
        <div className="card-profile">
          <div className="card-body-profile">
            <p>Thông tin doanh nghiệp</p>
            <EditOutlined onClick={showModal} />
          </div>
          <div className="content-profile">
            <p>Domain:</p>
            <a
              href={`https://${userInfo?.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
              target="_blank"
              rel="noreferrer"
            >
              {`https://${userInfo?.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
            </a>
            {userInfo?.taxCode !== null && userInfo?.taxCode?.trim() && (
              <>
                <p>Mã số thuế:</p>
                <p>{userInfo?.taxCode}</p>
              </>
            )}
            {userInfo?.cardNumber !== null && userInfo?.cardNumber?.trim() && (
              <>
                <p>CMND/CCCD</p>
                <p>{userInfo?.cardNumber}</p>
              </>
            )}
            {userInfo?.companyName !== null && userInfo?.companyName?.trim() && (
              <>
                <p>Tên doanh nghiệp:</p>
                <p>{userInfo?.companyName}</p>
              </>
            )}
            <p>Địa chỉ kinh doanh:</p>
            <p>{userInfo?.address}</p>
            <p>Lĩnh vực kinh doanh:</p>
            <p>{userInfo?.business_type}</p>
          </div>
          <Modal
            showModal={props.modalForm?.isOpen}
            item={isModalVisible.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footerNode={renderFooterInfoCompany}
            onClose={handleCancel}
          >
            <Form className="body-info" form={form} layout="vertical">
              <Form.Item
                name="tenantId"
                label={<div className="title-info">Tên domain</div>}
              >
                <Input disabled={!props.isAdmin} />
              </Form.Item>
              {userInfo?.taxCode !== null && userInfo?.taxCode?.trim() &&  (
                <Form.Item
                  name="taxCode"
                  label={<div className="title-info">Mã số thuế</div>}
                >
                  <Input />
                </Form.Item>
              )}
              {userInfo?.cardNumber && userInfo?.cardNumber?.trim !== null && (
                <Form.Item
                  name="cardNumber"
                  label={<div className="title-info">CMND/CCCD</div>}
                >
                  <Input />
                </Form.Item>
              )}
              {userInfo?.companyName !== null && userInfo?.companyName?.trim()  && (
                <Form.Item
                  name="companyName"
                  label={<div className="title-info">Tên doanh nghiệp</div>}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item
                name="address"
                label={<div className="title-info">Địa chỉ kinh doanh</div>}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="business_type"
                label={<div className="title-info">Lĩnh vực hoạt động</div>}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      )}
    </>
  );
}

function PackageList({ packages, onUserReg }) {
  const limitedPackages = packages.filter(
    (p) => p.systemType === EBuyMoreSystem.NORMAL
  );
  const unlimitedPackages = packages.filter(
    (p) => p.systemType > EBuyMoreSystem.NORMAL
  );

  return (
    <>
      {unlimitedPackages.map((p) => (
        <UnlimitedPackage key={p.id} onUserReg={onUserReg} pkg={p} />
      ))}
      {limitedPackages.map((p, index) => (
        <LimitedPackage key={p.id} pkg={p} />
      ))}
    </>
  );
}

function UnlimitedPackage({ pkg, onUserReg }) {
  const formattedExpiredAt = pkg?.expiredAt
    ? dayjs(pkg.expiredAt).format("DD/MM/YYYY_HH:mm")
    : "-";
  const dataNumberUser = pkg.valueOther;

  const handleBuyMoreClick = () => {
    onUserReg(pkg);
  };
  return (
    <div className="card-add-user">
      <span>
        {pkg.packageName} - {pkg.serviceName}
      </span>
      <div className="content-use">
        <ul>
          <p>Đã mua user:</p>
          <p>{dataNumberUser} user</p>
        </ul>
        <ul>
          <p>Ngày hết hạn:</p>
          <p>{formattedExpiredAt}</p>
        </ul>
      </div>
      <div className="btn-change">
        <div className=" add-package">
          <ButtonCustomize
            title={"Mua thêm"}
            btnType={BtnTypeEnum.OUTLINE}
            btnSize={32}
            onPressHandle={handleBuyMoreClick}
          />
        </div>
      </div>
    </div>
  );
}

function LimitedPackage({ pkg }) {
  const formattedStartAt = pkg?.startedAt
    ? dayjs(pkg.startedAt).format("DD/MM/YYYY_HH:mm")
    : "";
  return (
    <div className="card-add-user">
      <span>
        {pkg.packageName} - {pkg.serviceName}
      </span>
      <div className="content-deployment">
        <p>Đã mua dịch vụ :</p>
        <p className={"desc-service"}>{pkg.description}</p>
        <ul>
          <p>Ngày mua:</p>
          <p>{formattedStartAt}</p>
        </ul>
        <ul>
          <p>Ngày hết hạn:</p>
          <p>-</p>
        </ul>
      </div>
    </div>
  );
}

function PaymentHistory({
  listTransaction,
  setSize,
  setPage,
  page,
  size,
  listTotal,
}) {
  return (
    <div className="table-content">
      <span>Lịch sử thanh toán</span>
      <TableComponent
        dataSource={listTransaction}
        totalCount={listTotal}
        page={page}
        setPage={setPage}
        pageSize={size}
        setSize={setSize}
        scrollSize={{
          x: 800,
        }}
        columns={[
          {
            title: <div className="content-column">Tên gói/Tên dịch vụ</div>,
            width: 175,
            dataIndex: "name",
            key: "name",
            render: (_, record) => {
              return <span className="content-column">{record.name}</span>;
            },
          },
          {
            title: <div className="content-column">Chi tiết</div>,
            width: 175,
            dataIndex: "status",
            key: "status",
            render: (_, record) => {
              const packageStatus = (_, row) => {
                if (row.status === STATUS_PACKAGE.new_register) {
                  return <span>Đăng ký gói {record.name} </span>;
                }

                if (row.status === STATUS_PACKAGE.buy_more && row.typeSystem) {
                  return <span>Mua {row.numOfUsers} user</span>;
                }

                if (row.status === STATUS_PACKAGE.buy_more) {
                  return <span>{record.name} </span>;
                }

                if (row.status === STATUS_PACKAGE.extend_pkg) {
                  return <span>Gia hạn gói {record.name}</span>;
                }

                if (row.status === STATUS_PACKAGE.changed) {
                  return <span>Thay đổi ký gói {record.name}</span>;
                }
                return null;
              };
              return (
                <div>
                  <span>{packageStatus(record.status, record)}</span>
                </div>
              );
            },
          },
          {
            title: <div className="content-column">Đã thanh toán</div>,
            width: 80,
            dataIndex: "amount",
            key: "amount",
            render: (_, record) => {
              return <span>{formatInputMoney(record.amount)} đ</span>;
            },
          },
          {
            title: <div className="content-column">Thời gian giao dịch</div>,
            width: 80,
            dataIndex: "createdAt",
            key: "createdAt",
            // fixed: 'right',
            render: (_, record) => {
              const createdAtFormat = record.createdAt
                ? dayjs(record.createdAt).format("DD/MM/YYYY_HH:mm")
                : "";
              return <span className="content-column">{createdAtFormat}</span>;
            },
          },
        ]}
      />
    </div>
  );
}

function Profile() {
  const [user, setUser] = useState(null);
  let userLocal = JSON.parse(localStorage.getItem(KEY_USER_LOGIN));
  let observer = UserObserver({});
  useEffect(() => {
    if (userLocal) {
      observer.getUserInfo(userLocal.id).then((res) => {
        setUser(res);
      });
    }
  }, []);

  const avatarSize = 150;
  return (
    <>
      <ProfileInfo avatarSize={avatarSize} user={user} userId={userLocal?.id} />
      {/* <Pricing/> */}
    </>
  );
}

export default Profile;
