import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Input, notification, Spin } from "antd";
import { useDispatch } from "react-redux";

import {
  AlertType,
  openNotificationWithIcon,
} from "../../../utils/AlertMessage";
import {
  API_BASE_URL,
  ACCESS_TOKEN_NAME,
  KEY_USER_LOGIN,
  REFRESH_ACCESS_TOKEN,
  EXP,
} from "../../../constants/apiConstants";
import {changePhoneNumberPrefix84} from "../../../utils/helper"
import LoginForm from "../LoginForm/LoginForm";
import "./RegistrationForm.scss";
import { setAuthInfo } from "../../../redux/slice/auth/authSlice";
import LogoTesop from "../../../assets/images/logo_tesop.png";
import Logo from "../../svg/logo";
import { firebaseApp, auth } from "./firebase/auth";
import { RecaptchaVerifier, signInWithPhoneNumber, getAuth } from 'firebase/auth';


function RegistrationForm(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [open, isOpen] = useState(Boolean);
  const [typeForm, setTypeForm] = useState("Register");
  const [formValue, setFormValue] = useState();
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [loading, setLoading] = useState(false);

  const show = () => {
    isOpen(true);
  };

  useEffect(() => {
    // Kiểm tra nếu đang ở localhost thì set appVerificationDisabledForTesting

    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // console.log(response);
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        console.log("Response expired. Ask user to solve reCAPTCHA again.");
      }
    });
    setRecaptchaVerifier(recaptchaVerifier);
    return () => {
      recaptchaVerifier.clear();
    }
  }, [auth])
  const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;


  const handleSendOtp = async (phoneNumberPrefix) => {
    if (!phoneNumberPrefix) return;
    const phoneNumber = changePhoneNumberPrefix84(phoneNumberPrefix)
    setLoading(true);
    const auth = getAuth();
    // signInWithPhoneNumber(auth, "+84366385391", recaptchaVerifier)
    return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setConfirmationResult(confirmationResult);
        return true;
      }).catch((error) => {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          'Lỗi gửi OTP: ' + error.message
        );
        console.error(error.message);
        return false;
      }).finally(() => {
        setLoading(false);
      });


  };

  const handleVerifyOtp = async (otp) => {
    if (!otp) return;
    try {
      const credential = await confirmationResult?.confirm(otp);
      console.log('Xác thực thành công, user:', credential?.user);
      return true;
    } catch (error) {
      console.error('Lỗi xác thực OTP:', error);
      openNotificationWithIcon(
        notification,
        AlertType.error,
        'Mã OTP không hợp lệ:'
      );
      console.log(error.message);
      return false
    }
  };
  const checkAccountExist = async (value) => {
    if (value) {
      setLoading(true);
      return axios.post(API_BASE_URL + 'auth/check/username', {
        "username": value,
      })
        .then((res) => {
          if (res?.data.data?.isExist === false) {
            form.setFields([
              {
                name: 'username',
                warnings: [],
                errors: [],
              },
            ]);
            return true;
          } else {
            form.setFields([
              {
                name: 'username',
                errors: ['tài khoản này đã tồn tại!'],
              },
            ]);
            return false;
          }
        }).catch((error) => {
          form.setFields([
            {
              name: 'username',
              errors: ['Hệ thống không thể kiểm tra tài khoản này!'],
            },
          ]);
          return false;
        }).finally(()=>{
          setLoading(false);
        })
    } else {
      return false;
    }


  }
  const onFinish = async (values) => {
    const resultAccountExist = await checkAccountExist(values.username);
  
    if (!resultAccountExist) {
      return;
    }
    const resultSendOtp = await handleSendOtp(values.phoneNumber);
    if (resultSendOtp) {
      setTypeForm("VerifyCode")
      setFormValue({
        username: values.username,
        password: values.password,
        email: values.email,
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
      })
    }
  };

  const sendRegistForm = async (values) => {
    setLoading(true);
    const result = await handleVerifyOtp(values?.code);
    if(!result){
      setLoading(false)
      return;
    }
    if (formValue) {
      axios
        .post(API_BASE_URL + "auth/register", formValue)
        .then(function (response) {
          if (response.status === 200) {
            localStorage.setItem(ACCESS_TOKEN_NAME, response.data.data.token);
            localStorage.setItem(
              KEY_USER_LOGIN,
              JSON.stringify(response.data.data.user)
            );
            localStorage.setItem(
              REFRESH_ACCESS_TOKEN,
              response.data.data.refreshToken
            );
            localStorage.setItem(EXP, response.data.data.exp);
            dispatch(setAuthInfo(response.data.data.user));

            openNotificationWithIcon(
              notification,
              AlertType.success,
              "Đăng ký thành công"
            );
          }
        })
        .catch(function (error) {
          if (error.response?.status === 406) {
            openNotificationWithIcon(
              notification,
              AlertType.error,
              "Tài khoản đã tồn tại"
            );
          } else {
            openNotificationWithIcon(
              notification,
              AlertType.error,
              error?.response?.data?.message || "Lỗi hệ thống"
            );
          }
        }).finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      openNotificationWithIcon(
        notification,
        AlertType.error,
        "Vui lòng điền đầy đủ thông tin"
      );
    }

  }
  return (
    <div>
      {open === false && (
        <div className="register-card">
          <div className="login-card-mobile">
            {process.env.REACT_APP_IS_SAAS_PROD === "true" ? (
              <Logo />
            ) : (
              <img src={LogoTesop} alt={""} width={150} />
            )}
          </div>
          <div className="form-register">
            {loading ? (<>
              <div className="loading-container">
                <Spin indicator={antIcon} />
              </div>
            </>) : null}
            <h2>Đăng ký</h2>
            <Form
              className={"form"}
              name="basic"
              layout="vertical"
              form={form}
              onFinish={typeForm == "Register" ? onFinish : sendRegistForm}
              autoComplete="off"
            >
              <div id="recaptcha-container"></div>
              {
                typeForm == "Register" ? (
                  <>
                    <div className="col-wrap">
                      <div className={"form-item"}>
                        <Form.Item
                          label={
                            <>
                              <div className="title-form">Tài khoản</div>
                            </>
                          }
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng điền tài khoản của bạn",
                            },
                            {
                              pattern: /^[a-zA-Z0-9]*$/,
                              message:
                                "Tài khoản không được chứa ký tự đặc biệt, dấu cách hoặc ký tự có dấu.",
                            },
                          ]}
                        >
                          <Input
                            rootClassName={"form-input"}
                            placeholder={"Nhập vào"}
                          // onChange={(value) => {
                          //   return handleSearchUsername(value.target.value);
                          // }}
                          />
                        </Form.Item>
                      </div>
                      <div className={"form-item"}>
                        <Form.Item
                          label={
                            <>
                              <div className="title-form">Mật khẩu</div>
                            </>
                          }
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng điền mật khẩu của bạn",
                            },
                          ]}
                        >
                          <Input.Password
                            rootClassName={"form-input"}
                            placeholder={"Nhập vào"}
                          />
                        </Form.Item>
                      </div>
                      <div className={"form-item"}>
                        <Form.Item
                          name="fullName"
                          label={
                            <>
                              <div className="title-form">Họ và tên</div>
                            </>
                          }
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng điền họ tên của bạn",
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input
                            rootClassName={"form-input"}
                            placeholder={"Nhập vào"}
                          />
                        </Form.Item>
                      </div>
                      <div className={"form-item"}>
                        <Form.Item
                          name="phoneNumber"
                          label={
                            <>
                              <div className="title-form">Số điện thoại</div>
                            </>
                          }
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng điền số điện thoại của bạn",
                            },
                          ]}
                        >
                          <Input
                            addonBefore={<div>
                              +84
                            </div>}
                            rootClassName={"form-input-custom"}
                            placeholder={"Nhập vào"}
                            // onChange={(value) => {
                            //   return handleSearch(value.target.value);
                            // }}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div>
                      <div className={"form-item"}>
                        <Form.Item
                          name="email"
                          label={
                            <>
                              <div className="title-form">Email</div>
                            </>
                          }
                          rules={[
                            {
                              type: "email",
                              message: "Không đúng định dạng E-mail",
                            },
                            {
                              required: true,
                              message: "Vui lòng điền E-mail của bạn",
                            },
                          ]}
                        >
                          <Input
                            rootClassName={"form-input"}
                            placeholder={"Nhập vào"}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="footer-register">
                      Hãy đảm bảo các thông tin bạn đã nhập là đúng, việc này sẽ giúp
                      tài khoản của bạn được hỗ trợ tốt nhất về sau.
                    </div>
                    <Form.Item
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      <button className={"btn-register"} type={"submit"}  >
                        Đăng ký tài khoản
                      </button>
                    </Form.Item>
                  </>
                ) : (<>

                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    name="code"
                    label={
                      <>
                        <div className="title-form">Mã xác minh</div>
                      </>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng điền mã xác minh của bạn",
                      },
                    ]}
                  >
                    <Input
                      rootClassName={"form-input"}
                      placeholder={"Nhập mã xác minh"}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                  >
                    <button className={"btn-register"} type={"submit"}  >
                      Verify Code
                    </button>
                  </Form.Item>
                </>)
              }
            </Form>
            <div className="login">
              <div>Nếu bạn đã có tài khoản? </div>
              <Link to={"/login"}>
                <div className="loginText">
                  Đăng nhập ngay
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(RegistrationForm);
